import React, { useEffect } from "react";
// import { Helmet } from 'react-helmet';
import Layout from "../../components-pl/layout";
import Hero from "../../images/hero-subs.jpg";
import Link from "gatsby-link";
import SEO from "../../components-pl/seo";
import Revs from "../../components-pl/reviews";
import PricingTable from "../../components-pl/pricing-table";

const SubscriptionsPage = () => {
  useEffect(() => {
    document.getElementById("lang-switch").href = "/shroomscriptions/";
  }, []);
  return (
    <>
      <SEO
        title={"Subskrypcje"}
        header={{ siteTitle: " Plany miesięczne | Sklep" }}
      />
      <Layout>
        <div
          className="container-fluid"
          id="shroomscriptions"
          style={{
            padding: "0",
            minHeight: "1200px",
            backgroundImage: `url(${Hero})`,
            backgroundSize: "cover",
            backgroundColor: "#9FD4F0",
            marginTop: "-30px",
            backgroundAttachment: "fixed",
            backgroundPosition: "bottom center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            className="container has-text-centered"
            style={{ textAlign: "center", paddingTop: "30px" }}
          >
            <h1 id="shrooms1" className="mt-5 mb-5 display-5 spec-h text-white">
              Shroomskrypcje
            </h1>
            <div
              style={{
                background: "whitesmoke",
                opacity: "0.95",
                borderRadius: "20px 20px 0 0",
                padding: "2em",
              }}
            >
              <h2 className="mt-5 mb-5 display-10 spec-h">
                Podnieś swoje samopoczucie <br />
                dzięki naszym ekskluzywnym subskrypcjom shroom!{" "}
              </h2>
              <h3 className="mt-5 mb-5 display-10 spec-h">
                <span role="img" aria-label="stars">
                  ✨
                </span>{" "}
                Poznaj nasze 3 pakiety subskrypcyjne{" "}
                <span role="img" aria-label="stars">
                  ✨
                </span>
              </h3>
            </div>
            <PricingTable />
            <div
              style={{
                background: "whitesmoke",
                opacity: "0.9",
                padding: "2em",
              }}
            >
              <h4 className="display-10 spec-h">
                Przekształć swoją codzienną rutynę i postaw na priorytetowe
                dbanie o swoje zdrowie dzięki naszym subskrypcjom shroom. Zapisz
                się teraz i wyrusz w podróż ku zdrowszej, bardziej energetycznej
                wersji siebie!
              </h4>
              <h5 className="mt-5 mb-2 spec-h">
                <span role="img" aria-label="stars">
                  ✨
                </span>{" "}
                Dlaczego warto wybrać nasze subskrypcje?{" "}
                <span role="img" aria-label="stars">
                  ✨
                </span>
              </h5>
              <p style={{ textAlign: "left", color: "#1D1D1D" }}>
                <span role="img" aria-label="grzybowe składniki">
                  🍄
                </span>{" "}
                <b>Specjalne składniki</b>:<br /> Nasze receptury zawierają
                potężną mieszankę cordyceps i lion's mane, znanej ze swoich
                adaptogennych i stymulujących właściwości poznawczych.
                <br />
                <br />
                <span role="img" aria-label="Zdrowe napoje">
                  🌿
                </span>{" "}
                <b>Korzyści zdrowotne</b>:<br /> Ciesz się naturalnym i zdrowym
                sposobem na poprawę energii, koncentracji i relaksu bez
                sztucznych dodatków.
                <br />
                <br />
                <span role="img" aria-label="dostawa">
                  🚚
                </span>{" "}
                <b>Wygodna dostawa</b>:<br /> Otrzymuj swoje miesięczne dostawy
                butelek Shroom wygodnie dostarczane pod twoje drzwi, bez
                zbędnego kłopotu.
              </p>
            </div>
            <div
              style={{
                background: "#FFD36D",
                opacity: "0.9",
                padding: "20px",
              }}
            >
              <h3 className="mt-2 mb-2 display-10 spec-h">
                <span role="img" aria-label="power">
                  🚀
                </span>{" "}
                Subskrypcja – Power x 24
              </h3>
              <p className="text-black" style={{ textAlign: "left" }}>
                Uwolnij moc w sobie dzięki naszej premium mieszance Shroom
                Power! Pełne specjalnych składników, takich jak cordyceps i
                lion's mane, każde opakowanie to potężny eliksir stworzony do
                zwiększenia energii i skupienia. Zapisz się teraz za jedyne 269
                PLN miesięcznie i ciesz się korzyściami z 24 butelek Power
                dostarczanych prosto do twojego domu. Zasil swoje dni
                vitalnością i pokonuj wyzwania bez wysiłku.
              </p>
            </div>
            <div
              style={{
                background: "pink",
                opacity: "0.9",
                padding: "20px",
              }}
            >
              <h3 className="mt-2 mb-2 display-10 spec-h">
                <span role="img" aria-label="mix">
                  🌈
                </span>{" "}
                Subskrypcja – Mix 12 + 12
              </h3>
              <p className="text-black" style={{ textAlign: "left" }}>
                Doświadcz najlepszego z obu światów dzięki naszej subskrypcji
                Mix 12 + 12! Skorzystaj z harmonijnej mieszanki 12 butelek Relax
                i 12 butelek Power miesięcznie, starannie dobranej, aby
                zrównoważyć twój umysł i ciało. Nasza unikalna mieszanka
                cordyceps i lion's mane zapewni Ci holistyczną podróż do
                zdrowia. Za jedyne 269 PLN miesięcznie wyrusz na ścieżkę relaksu
                i odnowionej energii dzięki tej doskonale zrównoważonej
                mieszance.
              </p>
            </div>
            <div
              style={{
                background: "lightblue",
                opacity: "0.9",
                padding: "20px",
              }}
            >
              <h3 className="mt-2 mb-2 display-10 spec-h">
                <span role="img" aria-label="relax">
                  😌
                </span>{" "}
                Subskrypcja – Relax x 24
              </h3>
              <p className="text-black" style={{ textAlign: "left" }}>
                Obejmij spokój dzięki naszej subskrypcji Shroom Relax! Pogłęb
                się w kojących efektach 24 butelek Relax miesięcznie,
                wzbogaconych o cordyceps i lion's mane, by doświadczyć
                relaksującego doświadczenia jak żadne inne. Zrelaksuj się po
                stresach codziennego życia i postaw swoje samopoczucie
                psychiczne na pierwszym miejscu. Zapisz się już dziś za jedyne
                269 PLN miesięcznie i wprowadź relaks do swojej codziennej
                rutyny.
              </p>
            </div>
            <div
              style={{
                background: "whitesmoke",
                opacity: "0.9",
                padding: "2em",
                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <p style={{ textAlign: "center" }}>
                <Link to="/pl/" className="btn btn-outline-dark mush">
                  wróć do sklepu
                </Link>
              </p>
            </div>
          </div>
          <div style={{ marginTop: "200px" }}>
            <Revs />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SubscriptionsPage;
